<template>
  <b-card style="width:100%">
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="回滚审批单编号"
              label-for="rollbackNo"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.rollbackNo"
                name="rollbackNo"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="关联订单号"
              label-for="relationalNo"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.relationalNo"
                name="relationalNo"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="状态"
              label-for="status"
              label-size="sm"
          >
            <v-select
                id="status"
                :options="getCodeOptions('stock_rollback_status')"
                v-model="condition.status"
                name="status"
                class="select-size-sm"
                placeholder="请选择状态"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="3"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
          >
            <span class="align-right">重置</span>
          </b-button>

        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import stockrollbackStore from '@/views/apps/stockrollback/stockrollbackStore'

export default {
  components: {},
  props: {},
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stockrollback')) store.registerModule('stockrollback', stockrollbackStore)

    onMounted(()=>{
      state.condition = store.getters['stockrollback/getCondition']
    })

    const state = reactive({
      condition: {},
    })
    const methods = {
      refreshTable() {
        emit('refreshTable')
      },
      resetCondition() {
        state.condition = {}
        store.commit('stockrollback/updateCondition', state.condition)
        methods.refreshTable()
      },
    }
    return {
      ...toRefs(state),
      ...methods,
      //filter
      getCodeOptions
    }
  },
}
</script>

<style scoped>

</style>
